import axios from 'axios';
import { useEffect } from 'react';

const initiatePurchase = (token, id) => {
  let url = 'https://api.fanfliq.com/v1/order/create/dodo';

  axios
    .post(
      url,
      {
        contentId: id,
        source: 'mobile',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(response => {
      if (response && response.status === 200) {
        let options = {
          ...response.data,
          redirect: true,
        };

        if (options.paymentLink) {
          window.location.href = options.paymentLink;
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const BuyAlbum = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const id = params.get('id');

    if (token && id) {
      initiatePurchase(token, id);
    }
  }, []);

  return (
    <div className="buy-album-container">
      <div className="loading-spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="mt-3">Processing your purchase...</div>
      </div>
    </div>
  );
};
