import { createGlobalStyle } from 'styled-components';
import { media } from './media';
import { SELECTED_THEME, themeColors } from './theme';

// 10px
export const GlobalStyle = createGlobalStyle`
 html,
  body {
    height: 100%;
    font-family: Lato, Arial, sans-serif;
    margin: 0;
    padding: 0;

    ${media.mobile`font-size: 62.5%`};
    ${media.small`font-size: 62.5%`};
    ${media.medium`font-size: 62.5%`};   
    ${media.large`font-size: 62.5%`};
    ${media.xlarge`font-size: 62.5%`};
    ${media.xxlarge`font-size: 75%`};
  }

  .txt-btn {
    cursor: pointer;
    :hover {
      color: ${themeColors[SELECTED_THEME].brand}
    }
  }

  .modal-dialog-menu {
    > .modal-dialog > .modal-content {
      background-color: ${themeColors[SELECTED_THEME].primary};
    }
  }

  .margin-head {
    ${media.mobile`margin-top: 10rem`};
    ${media.small`margin-top: 10rem`};
    ${media.medium`margin-top: 8rem`};
  }

  .hor-flex {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    ${media.mobile`width: auto`};
    ${media.small`width: auto`};
    ${media.medium`width: 60%`};  
  }

  .margin-top-16 {
    ${media.mobile`margin-top: 1.6rem`};
    ${media.small`margin-top: 1.6rem`};
    ${media.medium`margin-top: 1.6rem`};  
  }

  .padding-top-8 {
    ${media.mobile`padding-top: 6.4rem`};
    ${media.small`padding-top: 6.4rem`};
    ${media.medium`padding-top: 8rem`};  
  }

  .padding-top-12 {
    ${media.mobile`padding-top: 6.4rem`};
    ${media.small`padding-top: 6.4rem`};
    ${media.medium`padding-top: 14rem`};  
  }

  .margin-top-144 {
    ${media.mobile`margin-top: 6.4rem`};
    ${media.small`margin-top: 6.4rem`};
    ${media.medium`margin-top: 14.4rem`};  
  }

  .margin-top-24 {
    ${media.mobile`margin-top: 1.6rem`};
    ${media.small`margin-top: 1.6rem`};
    ${media.medium`margin-top: 2.4rem`};  
  }

  .margin-top-32 {
    ${media.mobile`margin-top: 3.2rem`};
    ${media.small`margin-top: 3.2rem`};
    ${media.medium`margin-top: 3.2rem`};  
  }

  .Toastify__toast--success {
    border-left: 6px solid #28A745;
    overflow: hidden;
  }
  
  .Toastify__close-button {
    align-self: center;
    > svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .filter-hover {
    cursor: pointer;
    :hover {
      transform: scale(1.06);
    }
  }

  .red-bar {
    width: 5px;
    height: 24px;
    border-radius: 64px;
    background: var(--FF-Brand-1, #DC143C);
  }

  .circle-white {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 64px;
    cursor: pointer;
    :hover {
      transform: scale(1.06);
    }
    background: var(--Greay-Shades-95, #F1F1F3);  
  }
  
  .Toastify__toast--error {
    border-left: 6px solid var(--toastify-color-progress-error);
    overflow: hidden;
  }
  
  .Toastify__toast-body {
    color: var(--text-light-primary, #1C262C);
    /* B1 */
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.2rem; /* 200% */
    letter-spacing: 1.28px;
  }

  .margin-top-106 {
    ${media.mobile`margin-top: 10.6rem`};
    ${media.small`margin-top: 10.6rem`};
    ${media.medium`margin-top: 10.6rem`};  
  }

  .margin-top-48 {
    ${media.mobile`margin-top: 4.8rem`};
    ${media.small`margin-top: 4.8rem`};
    ${media.medium`margin-top: 4.8rem`};  
  }

  .margin-top-48-small {
    ${media.mobile`margin-top: 4rem`};
    ${media.small`margin-top: 4rem`};
    ${media.medium`margin-top: 4.8rem`};  
  }

  .margin-top-40 {
    ${media.mobile`margin-top: 4rem`};
    ${media.small`margin-top: 4rem`};
    ${media.medium`margin-top: 4rem`};  
  }

  .margin-top-56 {
    ${media.mobile`margin-top: 5.6rem`};
    ${media.small`margin-top: 5.6rem`};
    ${media.medium`margin-top: 5.6rem`};  
  }

  .resp-icon-32 {
    ${media.mobile`width: 2.4rem; height: 2.4rem`};
    ${media.small`width: 2.4rem; height: 2.4rem`};
    ${media.medium`width: 3.2rem; height: 3.2rem`};  
  }

  .text-14 {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 500;
    ${media.mobile`font-size: 1.2rem; line-height: 2rem`};
    ${media.small`font-size: 1.2rem; line-height: 2rem`};
    ${media.medium`font-size: 1.4rem; line-height: 1.6rem`};   
  }

  .circle-image {
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
  }

  .circle-image-9 {
    height: 9.6rem;
    width: 9.6rem;
    border-radius: 100%;
  }

  .subtitle-modal {
    color: ${themeColors[SELECTED_THEME].black30};
    font-family: Poppins;
    font-weight: 400;
    ${media.mobile`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.4rem; line-height: 2.4rem`};   
  }

  .parent-div {
    padding-bottom: 6.4rem;
  }

  .text-12 {
    color: ${themeColors[SELECTED_THEME].black60};
    font-family: Poppins;
    font-weight: 400;
    ${media.mobile`font-size: 1rem; line-height: 2.4rem`};
    ${media.small`font-size: 1rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.2rem; line-height: 2.4rem`};   
  }

  .text-10 {
    color: ${themeColors[SELECTED_THEME].white};
    font-family: Poppins;
    font-weight: 500;
    text-transform: uppercase;
    ${media.mobile`font-size: 1rem; line-height: 1.6rem`};
    ${media.small`font-size: 1rem; line-height: 1.6rem`};
    ${media.medium`font-size: 1rem; line-height: 1.6rem`};   
  }

  .line-16 {
    ${media.mobile`line-height: 1.6rem`};
    ${media.small`line-height: 1.6rem`};
    ${media.medium`line-height: 1.6rem`};   
  }

  .otp {
    color: ${themeColors[SELECTED_THEME].primary};
    font-family: Poppins;
    font-weight: 500;
    ${media.mobile`margin-right: 1rem !important; width: 3.2rem  !important; height: 3.2rem !important; font-size: 1.2rem; line-height: 1.6rem`};
    ${media.small`margin-right: 1rem !important; width: 4.8rem  !important; height: 4.8rem !important;font-size: 1.2rem; line-height: 1.6rem`};
    ${media.medium`margin-right: 2.4rem !important; width: 4.8rem  !important; height: 4.8rem !important; font-size: 1.4rem; line-height: 2.4rem`};
    border-radius: 0.4rem;   
    border: 0.2rem solid ${themeColors[SELECTED_THEME].textSecondary} ;
    :focus {
      outline: none !important;
      border:1px solid ${themeColors[SELECTED_THEME].brand};
      box-shadow: 0 0 10px #719ECE;
    }
  }

  .line-32 {
    ${media.mobile`line-height: 3.2rem`};
    ${media.small`line-height: 3.2rem`};
    ${media.medium`line-height: 3.2rem`}; 
  }

  .text-20 {
    color: ${themeColors[SELECTED_THEME].white};
    font-family: Poppins;
    font-weight: 900;
    ${media.mobile`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.medium`font-size: 2rem; line-height: 3.2rem`};  
  }

  .text-20-black {
    color: ${themeColors[SELECTED_THEME].btnTxt};
    font-family: Poppins;
    font-weight: 700;
    ${media.mobile`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.medium`font-size: 2rem; line-height: 3.2rem`};  
  }

  .text-32 {
    color: ${themeColors[SELECTED_THEME].textTertiary};
    font-family: Poppins;
    font-weight: 900;
    ${media.mobile`font-size: 1.6rem; line-height: 5.6rem`};
    ${media.small`font-size: 1.6rem; line-height: 5.6rem`};
    ${media.medium`font-size: 3.2rem; line-height: 5.6rem`};  
  }

  .signup {
    color: ${themeColors[SELECTED_THEME].btnTxt};
    font-family: Poppins;
    font-weight: 900;
    ${media.mobile`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.medium`font-size: 2rem; line-height: 3.2rem`};  
    .bold {
      font-weight: 900;
    } 
  }

  .text-16 {
    color: ${themeColors[SELECTED_THEME].white};
    font-family: Poppins;
    font-weight: 700;
    ${media.mobile`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.6rem; line-height: 2.4rem`};   
  }

  .text-16-bold {
    color: ${themeColors[SELECTED_THEME].black20};
    font-family: Poppins;
    font-weight: 500;
    ${media.mobile`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.6rem; line-height: 2.4rem`};   
  }

  .text-16-head {
    color: ${themeColors[SELECTED_THEME].white};
    font-family: Poppins;
    font-weight: 700;
    ${media.mobile`font-size: 1.4rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.4rem; line-height: 3.2rem`};
    ${media.medium`font-size: 1.6rem; line-height: 3.2rem`};   
  }

  .text-head {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 700;
    ${media.mobile`font-size: 4.8rem; line-height: 5.6rem`};
    ${media.small`font-size: 4.8rem; line-height: 5.6rem`};
    ${media.medium`font-size: 4.8rem; line-height: 5.6rem`}; 
  }

  .text-head-light {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 300;
    ${media.mobile`font-size: 4.8rem; line-height: 6.4rem`};
    ${media.small`font-size: 4.8rem; line-height: 6.4rem`};
    ${media.medium`font-size: 4.8rem; line-height: 6.4rem`}; 
  }

  .section {
    ${media.mobile`margin-top: 4.8rem`};
    ${media.small`margin-top: 4.8rem`};
    ${media.medium`margin-top: 4.8rem`}; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .display {
    opacity: 1;
  }

  .section-div {
    background: ${themeColors[SELECTED_THEME].primary};
    ${media.mobile`padding: 1.6rem 1.6rem 3.2rem 1.6rem`};
    ${media.small`padding: 1.6rem 1.6rem 3.2rem 1.6rem`};
    ${media.medium`padding: 6.4rem 0 16rem 16rem;`};
    height: 100%;
  }

  .animation {
    width: 0%;
    height: 0%;
    border-width: 250;
   }

  .text-section-title {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 900;
    ${media.mobile`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.6rem; line-height: 3.2rem`};
    ${media.medium`font-size: 2.4rem; line-height: 3.2rem`}; 
  }

  .text-section-subtitle {
    color: ${themeColors[SELECTED_THEME].black60};
    font-family: Poppins;
    font-weight: 500;
    ${media.mobile`font-size: 1.2rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.rem; line-height: 3.2rem`};
    ${media.medium`font-size: 1.2rem; line-height: 3.2rem; margin-top: 0.8rem`}; 
  }

  .section-144-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    ${media.mobile`padding: 4.8rem 0; gap: 2.4rem`};
    ${media.small`padding: 4.8rem 0; gap: 2.4rem`};
    ${media.medium`padding: 14.4rem 0; gap: 2.4rem`}; 
    ${media.large`padding: 14.4rem 0; gap: 2.4rem`}; 
    > img, div {
      flex-basis: 0;
    }
    > div {
      min-width: 74.1rem;
    }
    > img {
      width: 52.3rem;
    }
  }

  .btn-black {
    border-radius: 12rem;
    background: transparent;
    display: flex;
    border: 1px solid ${themeColors[SELECTED_THEME].brand};
    align-items: center;
    justify-content: center;
    color: ${themeColors[SELECTED_THEME].black60};
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem; /* 200% */
    text-transform: uppercase;
    ${media.mobile`padding: 1.6rem;height: 4rem;`};
    ${media.small`padding: 1.6rem;height: 4rem;`};
    ${media.medium`padding: 1.6rem;height: 5.6rem;`}; 
    ${media.large`padding: 1.6rem;height: 5.6rem;`};
  }

  .btn-black-hover {
    cursor: pointer;
    :hover {
      background: ${themeColors[SELECTED_THEME].brand};
      color: ${themeColors[SELECTED_THEME].white};
    }
  }

  .logout-div {
    :hover {
      background: ${themeColors[SELECTED_THEME].brand};
    }
  }

  .btn-brand-small {
    border-radius: 13.6rem;
    border: 0.1rem solid ${themeColors[SELECTED_THEME].brand};
    background: ${themeColors[SELECTED_THEME].brand};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mobile`padding: 1.6rem 0;height: 4rem`};
    ${media.small`padding: 1.6rem 0;height: 4rem`};
    ${media.medium`padding: 1.6rem 0;height: 4.8rem; min-width: 19.2rem`}; 
    ${media.large`padding: 1.6rem 0;height: 4.8rem; min-width: 19.2rem`};
    :hover {
        transform: scale(1.04);
    }
  }  

  .tag {
    border-radius: 88px;
    background: rgba(255, 255, 255, 0.08);
    width: fit-content;
    ${media.mobile`padding: 0.4rem 1.6rem;`};
    ${media.small`padding: 0.4rem 1.6rem;`};
    ${media.medium`padding: 0.4rem 1.6rem;`}; 
    ${media.large`padding: 0.4rem 1.6rem;`}; 
  }

  .tag-btn {
    cursor: pointer;
    border-radius: 88px;
    border: 1px solid ${themeColors[SELECTED_THEME].black60};
    width: fit-content;
    cursor: pointer;
    ${media.mobile`padding: 0.4rem 1.6rem;`};
    ${media.small`padding: 0.4rem 1.6rem;`};
    ${media.medium`padding: 0.4rem 1.6rem;`}; 
    ${media.large`padding: 0.4rem 1.6rem;`}; 

    :hover {
      background: ${themeColors[SELECTED_THEME].brand};
      color: ${themeColors[SELECTED_THEME].black60};
    }
  }

  .modal-dialog-login {
    ${media.mobile`margin: 2.4rem;width:  -webkit-fill-available;`};
    ${media.small`margin: 2.4rem;width:  -webkit-fill-available`};
    ${media.medium`margin: auto;width:100%`};   
  
    > .modal-dialog {
      justify-content: center;
      max-width: 50rem;
    }
  
    > .modal-dialog > .modal-content {
      border-radius: 1.6rem;
      padding: 0;
      overflow: hidden;
    }
  }

  .modal-dialog-common {
    ${media.mobile`margin: 2.4rem;width:  -webkit-fill-available;`};
    ${media.small`margin: 2.4rem;width:  -webkit-fill-available`};
    ${media.medium`margin: auto; width:100%`};   
  
    > .modal-dialog {
      justify-content: center;
      ${media.mobile`width: 100%;`};
      ${media.small`width: 100%`};
      ${media.medium`width: 80rem`};   
    }

    > .dialog-small {
      justify-content: center;
      ${media.mobile`width: 100%;`};
      ${media.small`width: 100%`};
      ${media.medium`width: 60rem`};   
    }
  
    > .modal-dialog > .modal-content {
      border-radius: 2.4rem;
      padding: 0;
      background: var(--Black-Shades-08, #141414);
      overflow: hidden;
    }
  }

  .primary-btn {
    border-radius: 13.6rem;
    border: 0rem solid ${themeColors[SELECTED_THEME].primary};
    background: ${themeColors[SELECTED_THEME].primary};
    cursor: pointer;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    ${media.mobile`padding: 1.6rem 0; gap: 0.4rem;height: 4.8rem;`};
    ${media.small`padding: 1.6rem 0; gap: 0.4rem;height: 4.8rem;`};
    ${media.medium`padding: 1.6rem 0; gap: 0.8rem;height: 4.8rem;`}; 
    ${media.large`padding: 1.6rem 0; gap: 0.8rem;height: 4.8rem;`};
    :hover {
      border: 0rem solid ${themeColors[SELECTED_THEME].primary};
      background: ${themeColors[SELECTED_THEME].primary};
    }
  }
  
  .secondary-btn {
    border-radius: 0.8rem;
    background: ${themeColors[SELECTED_THEME].grey97};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mobile`padding: 1.6rem 0; gap: 0.4rem;height: 4rem;`};
    ${media.small`padding: 1.6rem 0; gap: 0.4rem;height: 4rem;`};
    ${media.medium`padding: 1.6rem 0; gap: 0.8rem;height: 4rem;`}; 
    ${media.large`padding: 1.6rem 0; gap: 0.8rem;height: 4rem;`};
    :hover {
      background: #E4E4E7;
    }
  }

  .info-modal {
    width: 100%;
    text-align: center;
    ${media.mobile`margin-top: 2.4rem`};
    ${media.small`margin-top: 2.4rem`};
    ${media.medium`margin-top: 2.4rem`}; 
    ${media.large`margin-top: 2.4rem`};  
  }

  .text-38 {
    color: ${themeColors[SELECTED_THEME].black20};
    font-family: Poppins;
    font-weight: 400;
    ${media.mobile`font-size: 2rem; line-height: 2.4rem`};
    ${media.small`font-size: 2rem; line-height: 2.4rem`};
    ${media.medium`font-size: 3.8rem; line-height: 4.8rem`};  
  }  

  .margin-8 {
    ${media.mobile`margin-top: 0rem`};
    ${media.small`margin-top: 0rem`};
    ${media.medium`margin-top: 0.8rem`};  
  }

  .btn-grey {
    border-radius: 13.6rem;
    border: 0.1rem solid ${themeColors[SELECTED_THEME].grey97};
    background: ${themeColors[SELECTED_THEME].grey97};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mobile`padding: 1.6rem 0; gap: 0.4rem;height: 4.8rem;`};
    ${media.small`padding: 1.6rem 0; gap: 0.4rem;height: 4.8rem;`};
    ${media.medium`padding: 1.6rem 0; gap: 0.8rem;height: 6.4rem;`}; 
    ${media.large`padding: 1.6rem 0; gap: 0.8rem;height: 6.4rem;`};
    :hover {
      background: #E4E4E7;
    }
  }

  .form-check-input:checked {
    background-color: ${themeColors[SELECTED_THEME].brand};
    border-color: ${themeColors[SELECTED_THEME].brand};
  }
  
  .btn-brand {
    border-radius: 13.6rem;
    border: 0.1rem solid ${themeColors[SELECTED_THEME].brand};
    background: ${themeColors[SELECTED_THEME].brand};
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mobile`padding: 1.6rem 0; gap: 0.4rem;height: 4.8rem;`};
    ${media.small`padding: 1.6rem 0; gap: 0.4rem;height: 4.8rem;`};
    ${media.medium`padding: 1.6rem 0; gap: 0.8rem;height: 6.4rem;`}; 
    ${media.large`padding: 1.6rem 0; gap: 0.8rem;height: 6.4rem;`};
    :hover {
      background: ${themeColors[SELECTED_THEME].brand};
    }
    :disabled {
      border: 0;
      background: rgba(220, 20, 60, 0.40);
    }
  }

  @keyframes smooth-appear-high {
    to {
      bottom: 0rem;
      opacity: 1;
    }
  }

  .bottom-text {
    color: ${themeColors[SELECTED_THEME].black60};
    font-family: Poppins;
    font-weight: 400;
    text-align: center;
    ${media.mobile`font-size: 1rem; line-height: 1.6rem`};
    ${media.small`font-size: 1rem; line-height: 1.6rem`};
    ${media.medium`font-size: 1rem; line-height: 1.6rem`};  
  }

  .text-play {
    white-space: nowrap;
    ${media.mobile`font-size: 1rem; line-height: 1.6rem`};
    ${media.small`font-size: 1rem; line-height: 1.6rem`};
    ${media.medium`font-size: 1.2rem; line-height: 3.2rem`}; 
  }

  .text-play-sub {
    white-space: nowrap;
    ${media.mobile`font-size: 1.2rem; line-height: 1.6rem`};
    ${media.small`font-size: 1.2rem; line-height: 1.6rem`};
    ${media.medium`font-size: 1rem; ine-height: 2.4rem`};
  }

  .player-parent {
    width: 100%;
    position: fixed;
    opacity: 0;
    z-index: 8;
    bottom: -5%;
    left: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(12px);
    display: flex;
    animation: smooth-appear-high 0.3s ease forwards;
    align-items: center;
    ${media.mobile`justify-content: space-between; height: 5.8rem; padding: 0 0.8rem`};
    ${media.small`justify-content: space-around; height: 5.8rem; padding: 0 0.8rem`};
    ${media.medium`justify-content: space-around; height: 9.6rem; padding: 0 0rem`}; 
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 2px #fff;
  }

  .center-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    width: 56rem;
  }

  .left-controls {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 33.3%;
    gap: 1.6rem;
  }
  .right-controls {
    display: flex;
    align-items: center;
    gap: 0.8rem;    
  }

  .progress-wrapper {
    position: relative;
    width: 47.7rem;
    height: 1.6rem;
  }

  .progress-wrapper-mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    ${media.mobile`display: flex`};
    ${media.small`display: flex`};
    ${media.medium`display: none`}; 
  }

  .timeline {
    height: 0.4rem;
    border-radius: 72px;
    position: absolute;
    background: rgba(255, 255, 255, 0.30);
    top: 0;
    bottom: 0;
    margin: auto;    
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 47.7rem`}; 
  }
  .progressline {
    position: absolute;
    height: 0.4rem;
    border-radius: 72px;
    background: rgba(255, 255, 255, 1);
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .thumb {
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    background: #FFF;
    cursor: pointer;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    :hover {
      transform: scale(1.06);
    }
  }

  .input-slider {
    opacity: 1;
    position: absolute;
    top: 4px;
    width: 100%;
  }

  .volume-wrapper {
    position: relative;
    width: 12rem;
    height: 1.6rem;
  }

  .right-controls {
    display: flex;
    align-items: center;
  }

  .volumeline {
    position: absolute;
    height: 0.4rem;
    border-radius: 7.2rem;
    background: rgba(255, 255, 255, 0.30);
    width: 12rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .volchangeline {
    position: absolute;
    height: 0.4rem;
    border-radius: 7.2rem;
    background: rgba(255, 255, 255, 1);
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .player-control-hide {
    ${media.mobile`display: none`};
    ${media.small`display: none`};
    ${media.medium`display: flex`}; 
  }

  .player-control-show {
    ${media.mobile`display: flex`};
    ${media.small`display: flex`};
    ${media.medium`display: none`}; 
  }

  .menu-title {
    color: ${themeColors[SELECTED_THEME].primary};
    font-family: Poppins;
    font-weight: 700;
    text-align: center;
    ${media.mobile`font-size: 1.8rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.8rem; line-height: 3.2rem`};
    ${media.medium`font-size: 1.8rem; line-height: 3.2rem`};  
  }

  .hide-in-mobile {
    ${media.mobile`display: none`};
    ${media.small`display: none`};
    ${media.medium`display: flex;`};  
  }
  .hide-in-desktop {
    ${media.mobile`display: flex`};
    ${media.small`display: flex`};
    ${media.medium`display: none;`};  
  }
  .full-width {
    width: 100%;
  }

  .progress {
    position: sticky;
    top: 0;
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 0;
  }
  
  .progress svg {
    transform: translateX(-100px) rotate(-90deg);
  }
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 5%;
    fill: none;
  }
  
  .bg {
    stroke: var(--accent);
    opacity: 0.2;
  }
  
  .progress .indicator {
    stroke: var(--accent);
  }
  
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: var(--background);
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: var(--background);
  }

  .rtf {
    box-sizing: border-box;
    padding: 0 !important;
    position: fixed;
    white-space: nowrap;
    z-index: 9998;
    padding-left: 0;
    list-style: none;
    &.open {
      .rtf--mb {
        margin-bottom: 0.8rem;
        > * {
          transform-origin: center center;
          transform: rotate(360deg);
          transition: ease-in-out transform 0.2s;
        }
        > ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }
      .rtf--ab__c {
        &:hover {
          > span {
            transition: ease-in-out opacity 0.2s;
            opacity: 0.9;
          }
        }
        > span.always-show {
          transition: ease-in-out opacity 0.2s;
          opacity: 0.9;
        }
        &:nth-child(1) {
          transform: translateY(60px) scale(1);
          transition-delay: 0.03s;
          &.top {
            transform: translateY(0px) scale(1);
          }
        }
        &:nth-child(2) {
          transform: translateY(120px) scale(1);
          transition-delay: 0.09s;
          &.top {
            transform: translateY(0px) scale(1);
          }
        }
        &:nth-child(3) {
          transform: translateY(180px) scale(1);
          transition-delay: 0.12s;
          &.top {
            transform: translateY(0px) scale(1);
          }
        }
      }
    }
  }
  
  .rtf--mb__c {
    *:last-child {
      margin-bottom: 0;
    }
    &:hover {
      > span {
        transition: ease-in-out opacity 0.2s;
        opacity: 0.9;
      }
    }
    > span.always-show {
      transition: ease-in-out opacity 0.2s;
      opacity: 0.9;
    }
    > span {
      opacity: 0;
      transition: ease-in-out opacity 0.2s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 6px;
      margin-left: 4px;
      background: rgba(0, 0, 0, 0.75);
      padding: 2px 4px;
      border-radius: 2px;
      color: white;
      font-size: 13px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
      &.right {
        right: 100%;
      }
    }
  }
  
  .rtf--mb {
    height: 48px;
    width: 48px;
    z-index: 9999;
    background-color: #666666;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-user-drag: none;
    font-weight: bold;
    color: #f1f1f1;
    font-size: 18px;
    > * {
      transition: ease-in-out transform 0.2s;
    }
  }
  
  .rtf--ab__c {
    display: block;
    position: absolute;
    top: 0;
    right: -4px;
    padding: 10px 0;
    margin: (-10px) 0;
    transition: ease-in-out transform 0.2s;
    > span {
      opacity: 0;
      transition: ease-in-out opacity 0.2s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 6px;
      background: rgba(0, 0, 0, 0.75);
      padding: 2px 4px;
      border-radius: 2px;
      color: white;
      font-size: 13px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
      &.right {
        right: 100%;
      }
    }
    &:nth-child(1) {
      transform: translateY(-60px) scale(0);
      transition-delay: 0.21s;
      &.top {
        transform: translateY(60px) scale(0);
      }
    }
    &:nth-child(2) {
      transform: translateY(-120px) scale(0);
      transition-delay: 0.18s;
      &.top {
        transform: translateY(120px) scale(0);
      }
    }
    &:nth-child(3) {
      transform: translateY(-180px) scale(0);
      transition-delay: 0.15s;
      &.top {
        transform: translateY(180px) scale(0);
      }
    }
    &:nth-child(4) {
      transform: translateY(-240px) scale(0);
      transition-delay: 0.12s;
      &.top {
        transform: translateY(240px) scale(0);
      }
    }
    &:nth-child(5) {
      transform: translateY(-300px) scale(0);
      transition-delay: 0.09s;
      &.top {
        transform: translateY(300px) scale(0);
      }
    }
    &:nth-child(6) {
      transform: translateY(-360px) scale(0);
      transition-delay: 0.03s;
      &.top {
        transform: translateY(360px) scale(0);
      }
    }
  }
  
  .rtf--ab {
    height: 48px;
    width: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-user-drag: none;
    font-weight: bold;
    color: #141414;
    background: transparent;
    margin-right: 4px;
    font-size: 16px;
    z-index: 10000;
  }

  .tooltip-inner {
    color: ${themeColors[SELECTED_THEME].white};
    font-family: Poppins;
    font-weight: 500;
    text-align: center;
    padding: 1rem;
    ${media.medium`font-size: 1.2rem; line-height: 1.6rem`};  
  }
  
  .share-button {
    ${media.mobile`right: 6.4rem; top: 4.8rem; margin: auto; display: none`};
    ${media.small`right: 6.4rem; top: 4.8rem; margin: auto; display: none`};
    ${media.medium`right: 6.4rem; top: 4.8rem; margin: auto; display: block`};
  }
  
  .white-hover {
    filter: none;
    :hover {
      filter: brightness(0) invert(1)
    }
  }

  @keyframes shrink {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .tab {
    display: flex;
    padding: 0.4rem 1.6rem;
    cursor: pointer;  
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16.8rem;
    background: ${themeColors[SELECTED_THEME].black8};

    > div {
      color: ${themeColors[SELECTED_THEME].black60};
      font-family: Poppins;
      font-weight: 600;
      ${media.mobile`font-size: 1rem; line-height: 3.2rem`};
      ${media.small`font-size: 1rem; line-height: 3.2rem`};
      ${media.medium`font-size: 1.2rem; line-height: 3.2rem`};  
    }
  }

  .carousel-item-padding-40-px {
    ${media.mobile`min-width: 14.4rem !important; height: 21.6rem`};
    ${media.small`min-width: 14.4rem !important; height: 21.6rem`};
    ${media.medium`width: 19.2rem !important; height: auto`};
  }
  .react-multi-carousel-track {

  }
  .tab-selected {
    background: ${themeColors[SELECTED_THEME].brand} !important;

    > div {
      color: ${themeColors[SELECTED_THEME].textPrimary} !important;
    }
  }

  .album-subtitle {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 500;
    ${media.mobile`font-size: 1rem; line-height: 1.6rem`};
    ${media.small`font-size: 1rem; line-height: 1.6rem`};
    ${media.medium`font-size: 1.2rem; line-height: 1.6rem`};
  }

  .album-title {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 900;
    ${media.mobile`font-size: 1.8rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.8rem; line-height: 3.2rem`};
    ${media.medium`font-size: 3.2rem; line-height: 5.6rem`};
  }

  .center-mobile {
    display: flex;
    flex-direction: column;
    ${media.mobile`align-items: center`};
    ${media.small`align-items: center`};
    ${media.medium`align-items: flex-start`};
  }

  .react-multi-carousel-track {    
    ${media.mobile`gap: 0.8rem`};
    ${media.small`gap: 0.8rem`};
    ${media.medium`gap: 1.6rem`};
    position: relative; 
    transform-style: preserve-3d; 
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden; 
    will-change: transform,transition; 
    overflow-x: scroll !important; 
    display: flex;
  } 

  .logo { 
    height: 2.4rem;
  }

  .gap-32 {
    ${media.mobile`gap: 0.8rem`};
    ${media.small`gap: 0.8rem`};
    ${media.medium`gap: 3.2rem`};
  }

  .gap-16 {
    ${media.mobile`gap: 2.4rem`};
    ${media.small`gap: 2.4rem`};
    ${media.medium`gap: 1.6rem`};
  }

  .gap-16px {
    gap: 1.6rem;
  }

  .gap-16-small {
    ${media.mobile`gap: 0.4rem`};
    ${media.small`gap: 0.4rem`};
    ${media.medium`gap: 1.6rem`};
  }

  .gap-16-big {
    ${media.mobile`gap: 4rem`};
    ${media.small`gap: 4rem`};
    ${media.medium`gap: 1.6rem`};
  }

  .gap-8rem {
    ${media.mobile`gap: 0.4rem`};
    ${media.small`gap: 0.4rem`};
    ${media.medium`gap: 0.8rem`};
  }

  .p-16 {
    ${media.mobile`padding: 1.2rem`};
    ${media.small`padding: 1.2rem`};
    ${media.medium`padding: 1.6rem`};  
  }

  .menu-text-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 80px;
    border: 1px solid var(--Greay-Shades-95, #F1F1F3);
    font-family: Poppins;
    font-weight: 500;
    text-decoration: none;
    ${media.mobile`padding: 0.8rem 2.4rem; width: 100%; color: ${themeColors[SELECTED_THEME].textPrimary}; font-size: 1.2rem; line-height: 2.4rem`};
    ${media.small`padding: 0.8rem 2.4rem; width: 100%; color: ${themeColors[SELECTED_THEME].textPrimary}; font-size: 1.2rem; line-height: 2.4rem`};
    ${media.medium`padding: 0px 2.4rem; width: auto; color: ${themeColors[SELECTED_THEME].textPrimary}; font-size: 1.2rem; line-height: 4rem`};   
  }

  .section-web {
    width: 100%;
    display: flex;
    ${media.mobile`align-items: start; justify-content: start; padding: 4.8rem 2.4rem`};   
    ${media.small`align-items: start; justify-content: start; padding: 4.8rem 2.4rem`};   
    ${media.medium`align-items: center; justify-content: center; padding: 0`};   
    
    > div > .child {
      display: flex;
      ${media.mobile`flex-direction: column; height: auto; align-items: flex-start; justify-content: flex-start`};
      ${media.small`flex-direction: column; height: auto; align-items: center; justify-content: center`};
      ${media.medium`flex-direction: row; height: 100vh; align-items: center; justify-content: center`};
    }
  }

  .reverse-column {
    ${media.mobile`flex-direction: column-reverse !important`};
    ${media.small`flex-direction: column-reverse !important`};
    ${media.medium`flex-direction: row !important`};
  }

  .section-web-first {
    ${media.mobile`height: 69.6rem`};
    ${media.small`height: 69.6rem`};
    ${media.medium`height: 95vh`};
  }

  .section-web-left {
    ${media.mobile`justify-content: flex-start !important`};
    ${media.small`justify-content: flex-start !important`};
    ${media.medium`justify-content: flex-start !important`};
  }

  .title-web {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 700;
    text-decoration: none;
    ${media.mobile`font-size: 1.8rem; line-height: 2.4rem;`};
    ${media.small`font-size: 1.8rem; line-height: 2.4rem;`};
    ${media.medium`font-size: 3.2rem; line-height: 4rem;;`}; 
  }

  .title-web-large {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 700;
    text-decoration: none;
    ${media.mobile`font-size: 2.2rem; line-height: 3.2rem;`};
    ${media.small`font-size: 2.2rem; line-height: 3.2rem;`};
    ${media.medium`font-size: 4.8rem; line-height: 5.6rem;;`}; 
  }

  .subtitle-web {
    color: ${themeColors[SELECTED_THEME].textSecondary};
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
    ${media.mobile`font-size: 1.4rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.4rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.6rem; line-height: 2.4rem`}; 
  }

  .subtitle-14-web {
    color: ${themeColors[SELECTED_THEME].textSecondary};
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
    ${media.mobile`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.2rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.4rem; line-height: 2.4rem`}; 
  }

  .subtitle-16-web {
    color: ${themeColors[SELECTED_THEME].textSecondary};
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
    ${media.mobile`font-size: 1.4rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.4rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.6rem; line-height: 2.4rem`}; 
  }

  .subtitle-14-web-small {
    color: ${themeColors[SELECTED_THEME].textSecondary};
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
    ${media.mobile`font-size: 1rem; line-height: 1.2rem`};
    ${media.small`font-size: 1rem; line-height: 1.2rem`};
    ${media.medium`font-size: 1.4rem; line-height: 2.4rem`}; 
  }

  .brand-web {
    color: ${themeColors[SELECTED_THEME].brand};
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
    ${media.mobile`font-size: 1.4rem; line-height: 3.2rem`};
    ${media.small`font-size: 1.4rem; line-height: 3.2rem`};
    ${media.medium`font-size: 1.6rem; line-height: 3.2rem`}; 
  }

  .brand-web-large {
    z-index: 1000;
    position: relative;
    color: ${themeColors[SELECTED_THEME].brand};
    font-family: Poppins;
    font-weight: 700;
    text-decoration: none;
    ${media.mobile`font-size: 3.2rem; line-height: 4rem`};
    ${media.small`font-size: 3.2rem; line-height: 4rem`};
    ${media.medium`font-size: 11.2rem; line-height: 12rem`}; 
  }

  .brand-tag {
    border-radius: 16.8rem;
    background: ${themeColors[SELECTED_THEME].brand};
    align-self: flex-start;
    > div {
      color: ${themeColors[SELECTED_THEME].white};
      font-family: Poppins;
      font-weight: 500;
      text-decoration: none;
      ${media.mobile`font-size: 1rem; line-height: 2.4rem`};
      ${media.small`font-size: 1rem; line-height: 2.4rem`};
      ${media.medium`font-size: 1.2rem; line-height: 3.2rem`}; 
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    ${media.mobile`align-items: center`};
    ${media.small`align-items: center`};
    ${media.medium`align-items: flex-start`};    
  }

  .ph-16 {
    ${media.mobile`padding: 0 1.6rem`};
    ${media.small`padding: 0 1.6rem`};
    ${media.medium`padding: 0 1.6rem`};
  }

  .w-108 {
    ${media.mobile`width: 4rem`};
    ${media.small`width: 4rem`};
    ${media.medium`width: 10.8rem`}; 
  }

  .w-595 {
    ${media.mobile`width: fit-content`};
    ${media.small`width: fit-content`};
    ${media.medium`width: 59.5rem`};
  }

  .w-858 {
    ${media.mobile`width: fit-content`};
    ${media.small`width: fit-content`};
    ${media.medium`width: 85.8rem`};
  }

  .w-857 {
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 85.7rem`}; 
  }

  .w-1032 {
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 103.2rem`};
  }
  .w-508 {
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 50.8rem`};
  }

  .banner-bg {
    position: absolute;
    right: 0;
    z-index: -1;
    ${media.mobile`width: 100%; height: 75.2rem; margin-top: 0px; background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_mob_ff.webp) lightgray 50% / cover no-repeat`};
    ${media.small`width: 100%; height: 75.2rem; margin-top: 0px; background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_mob_ff.webp) lightgray 50% / cover no-repeat`};
    ${media.medium`width: 82.8rem; height: 110vh; margin-top: -8rem; background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_desktop.webp) lightgray 50% / cover no-repeat`};
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);  
  }

  .web-btn {
    border-radius: 10.4rem;
    cursor: pointer;
    width: fit-content;
    ${media.mobile`padding: 0.8rem 3.2rem`};
    ${media.small`padding: 0.8rem 3.2rem`};
    ${media.medium`padding: 0.8rem 4.8rem`};  
    background: ${themeColors[SELECTED_THEME].textPrimary};
    > div {
      color: ${themeColors[SELECTED_THEME].primary};
      font-family: Poppins;
      font-weight: 600;
      text-decoration: none;
      ${media.mobile`font-size: 1.2rem; line-height: 2.4rem`};
      ${media.small`font-size: 1.2rem; line-height: 2.4rem`};
      ${media.medium`font-size: 1.4rem; line-height: 2.4rem`}; 
    }
  }

  @keyframes marquee { 
      0% { 
          transform: translateX(0%); 
      } 

      100% { 
          transform: translateX(-100%); 
      } 
  } 

  @keyframes textAnimation { 
      0%, 
      100% { 
          transform: translateY(0); 
      } 

      50% { 
          transform: translateY(-10px); 
      } 
  } 

  .div-strip {
    width: 100%;
    overflow: hidden;
    background-color: ${themeColors[SELECTED_THEME].footer};
    ${media.mobile`height:12.4rem; padding: 1.2rem 0`};
    ${media.small`height:12.4rem; padding: 1.2rem 0`};
    ${media.medium`height:25.6rem; padding: 6.4rem 0`};
    > div {
      width: 100%;
      white-space: nowrap; 
      display: flex;
      align-items: center;
      animation: marquee 10s linear infinite; 
      > div {
        color: ${themeColors[SELECTED_THEME].black30};
        font-family: Poppins;
        font-weight: 600;
        animation: textAnimation 10s linear infinite; 
        text-decoration: none;
        ${media.mobile`font-size: 7.2rem; line-height: 9.6rem`};
        ${media.small`font-size: 7.2rem; line-height: 9.6rem`};
        ${media.medium`font-size: 11.2rem; line-height: 12.8rem`}; 
      } 
    }
  }

  .store-btn {
    ${media.mobile`width: 11.2rem;`};
    ${media.small`width: 11.2rem;`};
    ${media.medium`width: 14rem;`};
  }
  .text-align-center {
    ${media.mobile`text-align: center`};
    ${media.small`text-align: center`};
    ${media.medium`text-align: left`}; 
  }

  .w-24 {
    ${media.mobile`width: 1.2rem`};
    ${media.small`width: 1.2rem`};
    ${media.medium`width: 2.4rem`};
  }

  .accordion {
    --bs-accordion-bg: ${themeColors[SELECTED_THEME].black8};
    --bs-body-color: ${themeColors[SELECTED_THEME].textSecondary};
    --bs-accordion-border-color: transparent; 
    --bs-accordion-active-bg: transparent;
    --bs-accordion-active-color: ${themeColors[SELECTED_THEME].brand};
    --bs-accordion-btn-active-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgB7ZIxCsIwFIbfCx0cRIL1CCK41bUq1Bt4BL2BN/EIehOdipudWwcvIGRwcCiteYIQSqwvo5JvS5vv/5YAeDy/R9Gfbs4ykeAIOTZXmIdcxhEK3HaD8uASobs97ZDbgcfnwEilWVVXawSIuJH3eK0d0O5Yna7mf7RJeRivBIqdlrJ7GSwm6qg448Nbum/esQY4Ec54a6Atwh3/GrBF6Bt3nBVoRuhVcMfZATPyOjDHnbmE82UxmCXg8fwXT7rLeqxCPIW5AAAAAElFTkSuQmCC);
    --bs-accordion-btn-icon-width: 2.4rem;
    --bs-accordion-btn-icon: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imhlcm9pY29ucy1zb2xpZC9jaGV2cm9uLXJpZ2h0Ij4KPHBhdGggaWQ9IlZlY3RvciAzMzUgKFN0cm9rZSkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYuMjgwMyAxMS40Njk3QzE2LjU3MzIgMTEuNzYyNiAxNi41NzMyIDEyLjIzNzQgMTYuMjgwMyAxMi41MzAzTDguNzgwMzMgMjAuMDMwM0M4LjQ4NzQ0IDIwLjMyMzIgOC4wMTI1NiAyMC4zMjMyIDcuNzE5NjcgMjAuMDMwM0M3LjQyNjc4IDE5LjczNzQgNy40MjY3OCAxOS4yNjI2IDcuNzE5NjcgMTguOTY5N0wxNC42ODkzIDEyTDcuNzE5NjcgNS4wMzAzM0M3LjQyNjc4IDQuNzM3NDQgNy40MjY3OCA0LjI2MjU2IDcuNzE5NjcgMy45Njk2N0M4LjAxMjU2IDMuNjc2NzggOC40ODc0NCAzLjY3Njc4IDguNzgwMzMgMy45Njk2N0wxNi4yODAzIDExLjQ2OTdaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=);
    --bs-accordion-body-padding-y: 0rem;
    --bs-accordion-body-padding-x: 0rem;
    --bs-accordion-btn-icon-transform: rotate(180);
    gap: 0.8rem;
    display: flex;
    flex-direction: column;
  }

    .no-icon {
      > button {
        color: white !important;
      }
      > button::after {
        display: none;
      }
    }

  button:focus:not(:focus-visible){
    outline: none;
    box-shadow: none;
  }

  .accordion-header {
    padding: 0rem 0 0.8rem 0;
    background: ${themeColors[SELECTED_THEME].black8};
  }

  .accordion-button {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 700;
    ${media.mobile`font-size: 1.6rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.6rem; line-height: 2.4rem`};
    ${media.medium`font-size: 2rem; line-height: 3.2rem`}; 
    background-color: transparent;
    padding: 2.4rem 1.6rem 0 1.6rem;
    :focus {
      outline: none;
    }
  }

  .accordion-body {
    padding: 0.8rem 1.6rem 2.4rem 1.6rem;
    color: ${themeColors[SELECTED_THEME].textSecondary};
    font-family: Poppins;
    font-weight: 400;
    ${media.mobile`font-size: 1.4rem; line-height: 2.4rem`};
    ${media.small`font-size: 1.4rem; line-height: 2.4rem`};
    ${media.medium`font-size: 1.6rem; line-height: 2.4rem`}; 
    background-color: transparent;
    :focus {
      outline: none;
    }
  }

  .right-content {
    flex-direction: column;
    flex: 1;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    ${media.mobile`width: 0%; height: 0; display: none`};
    ${media.small`width: 0%; height: 0; display: none`};
    ${media.medium`width: 100%; height: 100vh; display: flex`}; 
  }

  .left-content {
  }

  .collection {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    position: relative;

    > * {
      box-sizing: border-box;
    }
  }

  .img-scroll {
    opacity: 0;
    filter: drop-shadow(0 0 130px rgba(220, 20, 60, .35));
    max-width: none;
    position: absolute;
  }

  .trigger-img {
    opacity: 0;
    transition: opacity .2s;
  }

  .trigger-text {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .img-active {
    opacity: 1;
  }

  .h-100vh {
    ${media.mobile`height: auto`};
    ${media.small`height: auto`};
    ${media.medium`height: 100vh`};
  }

  .gap-16-mobile {
    ${media.mobile`gap: 2.4rem`};
    ${media.small`gap: 2.4rem`};
    ${media.medium`gap: 0rem`};

  }

  .gap-72 {
    ${media.mobile`gap: 14.4rem`};
    ${media.small`gap: 14.4rem`};
    ${media.medium`gap: 0rem`};
  }

  .padding-top-72 {
    ${media.mobile`padding: 7.2rem 2.4rem`};
    ${media.small`padding: 7.2rem 2.4rem`};
    ${media.medium`padding: 0rem`};

  }

  .margin-top-70 {
    ${media.mobile`margin-top: 4.8rem`};
    ${media.small`margin-top: 4.8rem`};
    ${media.medium`margin-top: 7rem`};
  }

  .margin-top-72 {
    ${media.mobile`margin-top: 4.8rem`};
    ${media.small`margin-top: 4.8rem`};
    ${media.medium`margin-top: 7.2rem`};
  }

  .flex-responsive {
    display: flex;
    ${media.mobile`flex-direction: column`};
    ${media.small`flex-direction: column`};
    ${media.medium`flex-direction: row; align-items: center`};
  }

  .h-376 {
    height: 37.6rem;
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 21.7rem`};
  }


  .test-name {
    color: var(--Greay-Shades-75, #BFBFBF);
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.4rem; /* 150% */
    letter-spacing: -0.16px;
  }

  .accordion-item {
    border-radius: 0.8rem;
  }

  .p-88 {
    ${media.mobile`padding-bottom: 4.8rem;`};
    ${media.mobile`padding-bottom: 4.8rem;`};
    ${media.mobile`padding-bottom: 8.8rem;`};
  }

  .banner-bg-artist {
    ${media.mobile`padding: 10.4rem 2.4rem; background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_artist.webp) lightgray 50% / cover no-repeat`};
    ${media.small`padding: 10.4rem 2.4rem; background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_artist.webp) lightgray 50% / cover no-repeat`};
    ${media.medium`padding: 0rem; background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_artist.webp) lightgray 50% / cover no-repeat`}; 
    background-blend-mode: normal, luminosity;
  }

  .banner-bg-artist-2 {
    background-blend-mode: normal, luminosity;
    ${media.mobile`background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_artist2.webp) lightgray 50% / cover no-repeat`};
    ${media.small`background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_artist2.webp) lightgray 50% / cover no-repeat`};
    ${media.medium`background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/banner_artist2.webp) lightgray 50% / cover no-repeat`}; 
  }

  .banner-bg-artist-3 {
    background-blend-mode: normal, luminosity;
    ${media.mobile`background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/MacBook+Pro+14_+-+26type_of_fliq.webp) lightgray 50% / cover no-repeat`};
    ${media.small`background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/MacBook+Pro+14_+-+26type_of_fliq.webp) lightgray 50% / cover no-repeat`};
    ${media.medium`background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(https://d1ghqy629uyda.cloudfront.net/MacBook+Pro+14_+-+26type_of_fliq.webp) lightgray 50% / cover no-repeat`}; 
  }

  .gap-56 {
    ${media.mobile`gap: 3.2rem`};
    ${media.small`gap: 3.2rem`};
    ${media.medium`gap: 5.6rem`};
  }

  .margin-top-56 {
    ${media.mobile`margin-top: 2.4rem`};
    ${media.small`margin-top: 2.4rem`};
    ${media.medium`margin-top: 5.6rem`};
  }

  .width-382 {
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 38.2rem`};
  }

  .fliq-card {
    border-radius: 8px;
    background: var(--Black-Shades-08, #141414);
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .circle-image-3 {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
  }

  .text-12-small {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 400;
    ${media.mobile`font-size: 1.2rem; line-height: 2rem`};
    ${media.small`font-size: 1.2rem; line-height: 2rem`};
    ${media.medium`font-size: 1.2rem; line-height: 2rem`};   
  }

  .text-10-small {
    color: #B3B3B3;
    font-family: Poppins;
    font-weight: 400;
    ${media.mobile`font-size: 1rem; line-height: 1.6rem`};
    ${media.small`font-size: 1rem; line-height: 1.6rem`};
    ${media.medium`font-size: 1rem; line-height: 1.6rem`};   
  }

  .black-card {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background: var(--Absolute-Black, #000);
  }

  .round-image-9 {
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 0.4rem;
  }

  .round-btn-red {
    border-radius: 8px;
    background: var(--FF-Brand-1, #DC143C);
    display: flex;
    padding: 12px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 4rem;
    cursor: pointer;
    align-self: stretch;
  }

  .songs-list-bg {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Black-Shades-08, #141414);
  }

  .text-16-all {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.6rem; 
    line-height: 4rem;
  }

  .padding-32 {
    ${media.mobile`padding: 2.4rem`};
    ${media.small`padding: 2.4rem`};
    ${media.medium`padding: 3.2rem`};
  }

  .w-360 {
    ${media.mobile`width: 29.6rem`};
    ${media.small`width: 29.6rem`};
    ${media.medium`width: 36rem`};
  }

  .box-grey {
    border-radius: 8px;
    background: var(--Black-Shades-08, #141414);
    display: flex;
    width: 100%;
    padding: 12px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .padding-56 { 
    ${media.mobile`padding: 2.4rem 1.6rem 8.2rem 1.6rem`};
    ${media.small`padding: 2.4rem 1.6rem 8.2rem 1.6rem`};
    ${media.medium`padding: 5.6rem 0 15.4rem 0`}; 
  }

  .gap-20 {
    ${media.mobile`gap: 2rem`};
    ${media.small`gap: 2rem`};
    ${media.medium`gap: 2rem`};
  }

  .share-link-card {
    border: 1px solid var(--Black-Shades-15, #262626);
    background: ${themeColors[SELECTED_THEME].footer};
    ${media.mobile`width: 100%; height: 100vh; border-radius: 0rem;`};
    ${media.small`width: 100%; height: 100vh; border-radius: 0rem;`};
    ${media.medium`width: 36rem; height: auto; border-radius: 1.6rem;`};
  }

  .half-height {
    ${media.mobile`height: 50vh`};
    ${media.small`height: 50vh`};
    ${media.medium`height: auto`};  
  }

  .cover-thumbnail {
    ${media.mobile`width: 100%; height: 50vh; border-radius: 0`};
    ${media.small`width: 100%; height: 50vh; border-radius: 0`};
    ${media.medium`width: 36rem; height: 28rem; border-radius: 1.6rem 1.6rem 0 0`};    
    object-fit: cover;
  }

  .center-in-parent {
    position: fixed:
    inset: 0;
    margin: auto;
  }

  .subtitle-14-web-share {
    color: ${themeColors[SELECTED_THEME].textPrimary};
    font-family: Poppins;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.4rem !important; 
    line-height: 3.2rem !important;  
  }

  .subtitle-20-web-share {
    color: ${themeColors[SELECTED_THEME].white};
    font-family: Poppins;
    font-weight: 900;
    text-decoration: none;
    font-size: 20px; 
    line-height: 32px; 
  }

  .w-596 {
    ${media.mobile`width: 100%`};
    ${media.small`width: 100%`};
    ${media.medium`width: 59.6rem`};
  }  

  .header-bg {
    border-radius: 104px;
    border: 1px solid rgba(255, 255, 255, 0.14);
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(2px);
    padding: 0.8rem;
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    align-items:  center;
    > div > img {
      border-radius: 100%;
      ${media.mobile`width: 4.8rem; height: 4.8rem;`};
      ${media.small`width: 4.8rem; height: 4.8rem;`};
      ${media.medium`width: 5.6rem; height: 5.6rem;`};
    }

    > div > .text {
      color: ${themeColors[SELECTED_THEME].white};
      font-family: Poppins;
      font-weight: 900;
      ${media.mobile`font-size: 1.8rem; line-height: 3.2rem`};
      ${media.small`font-size: 1.8rem; line-height: 3.2rem`};
      ${media.medium`font-size: 2rem; line-height: 3.2rem`};  
    }
  }

  .buy-album-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  text-align: center;
}

/* These classes are from Bootstrap */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.text-primary {
  color: #0d6efd !important;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
`;
